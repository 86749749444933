/* 
font-family: 'NanumSquareLight';
font-family: 'NanumSquare';
font-family: 'NanumSquareBold';
font-family: 'NanumSquareExtraBold';
font-family: 'NanumSquareAcb';
font-family: 'NanumSquareAceb';
font-family: 'NanumSquareAcl';
font-family: 'NanumSquareAcr';
*/


/* html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	font-size: 100%;
	font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    font-family: "NanumSquare", sans-serif;
}
 */

html, body {
    height: 100%;
    
}

.site-layout{
background-color: #fff;
}
.App >div > .lms-layout{
background-color: #F7F7F7;
/* height: 100vh; */
}

#root, .App {
    height: 100%;
}
.App >div{
    min-height: 100%;
    position: relative;
    padding-bottom: 261px;
}

a{
    text-decoration: none;
}

ul,ol{
    list-style: none;
    padding-left: 0;
    margin: 0;
}

.editoerBox ul, .editoerBox ol{
    list-style: auto;
    padding-left: 20px;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
    white-space: nowrap; 
}

input{
    border: 0;
}
img{
    max-width: 100%;
}
img, svg {
    vertical-align: middle;
}
input:focus{
    outline: 0;
}
.container{
    max-width: 1320px;
    margin: 0 auto;
    padding: 0 1rem;
}

.pt50{
    padding-top: 50px;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}

.card{
        background-color: #fff;
    background-color: var(--card);
    border: 0 solid #0000;
    border-radius: 5px;
    box-shadow: 0 6px 24px 0 #3537510f;
    height: calc(100% - 30px);
    margin-bottom: 1.875rem;
    position: relative;
    transition: all .5s ease-in-out;
}

.p-button, .p-tabmenu{
    font-family: "NanumSquare", sans-serif;
}

.text-muted{
    color: #999;
}

.text-red{
    color: #E8374A;
}


/* 커스텀 dropdown */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-button::after {
    content: url('../img/down.svg');
    position: absolute;
    top: 50%;
    right: 10px; /* 화살표 위치 조절 */
    transform: translateY(-50%);
}

.dropdown-button {
    border: 1px solid #d1d5db;
    border-radius: 6px;
    padding: 0.75rem 3rem 0.75rem 0.75rem;
    background: #fff;
    cursor: pointer;
    font-size: 1rem;
    color: #4b5563;
    white-space: nowrap;
    font-family: "NanumSquare", sans-serif;
}

.dropdown-options {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: block;
    border: 0;
    border-radius: 6px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
    color: #4b5563;
      padding: 0.55rem 0;
          max-height: 150px;
    overflow-y: auto;
}

.dropdown-options li {
    cursor: pointer;
      padding: 0.75rem 1.25rem;
}

.dropdown-options li:hover {
    background-color: #f0f0f0;
}

.dropdown-options li[aria-selected="true"] {
    background-color: #cce5ff;
}


.customSel{
    height: 43px;
     -webkit-appearance:none; /* 크롬 화살표 없애기 */
    -moz-appearance:none; /* 파이어폭스 화살표 없애기 */
    appearance:none; /* 화살표 없애기 */
    border: 1px solid #d1d5db;
    border-radius: 6px;
    padding: 0.75rem 2rem 0.75rem 0.75rem;
    background: #fff;
    cursor: pointer;
    font-size: 1rem;
    color: #4b5563;
    white-space: nowrap;
    font-family: "NanumSquare", sans-serif;
    position: relative;
    background:url('../img/down.svg') no-repeat 95% 50%/20px auto;
}

.customSel:focus{
box-shadow: 0 0 5px #007bff;
}

.customSel option:disabled{
    color: #999;
}



fieldset{
    padding: 0;
    border: 0;
}

.w-100{
    width: 100%;
}


@media(max-width: 870px){
    .App >div{
      padding-bottom: 0;
    }
  }