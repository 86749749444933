
/* 화면에서 적용될 CSS 코드 */
@media screen {}

/* 인쇄 미리보기 화면에서 적용될 CSS 코드 */
@media print {

    @page {
        size: A4;
          margin: 5mm;  /* this affects the margin in the printer settings */
    }


    html,
    body {
        height: 100%;
        margin: 0;
        padding: 0;
        overflow: hidden;
        /* 프린트할 때 스크롤을 숨깁니다. */
    }

    body {
        /*  content:url('../img/bg.png'); */
        border: 3px solid #000;
        box-sizing: border-box;
        height: 100%;
        padding: 10px;
        position: relative;
    }

    .logo {
        content: url(../img/logo.png);
        padding-top: 5%;
        padding-left: 6%;
    }

    .ConfiBg {
        border: 1px solid #000;
        background-size: 50%;
        height: 100%;

    }

    .ConfiBg h2 {
        font-size: 35px;
        text-align: center;
        letter-spacing: 5px;
        padding: 30px 0;
    }

    .infotableBox {
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        height: auto;
        margin: 0 auto;
        width: 500px;
        padding: 30px 0;
        margin-bottom: 80px;
    }

    table,
    th,
    td {
    
        border-collapse: collapse;
    }

    .infotableBox table {
        width: 100%;
    }

    .infotableBox table tr th,
    .infotableBox table tr td {
        padding: 10px;
    }

    .infotableBox table tr th {
        text-align: left;
    }

    .tit{
        text-align: center;
        width: 100%;
        font-weight: bold;
    }
    .tit.text1{
        margin-bottom: 30px;
    } 
    .tit.text3{
       font-size: 20px;
       position: absolute;
       bottom: 60px;
    }
}