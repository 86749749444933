/* .lms-layout .headerBox{
    height: 157px;
} */
.lms-layout .contain {
    max-width: 1860px;
    margin: 0 auto;
    padding: 0 1rem;
}

/* 헤더 */
.lms-layout header .top {
    background-color: #222;
    padding: 26px 0 0;
    position: relative;
}

.lms-layout header .bottom {
    display: none;
}

.lms-layout header .contain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.lms-layout header .contain .logo {
    padding-bottom: 1.5rem;
}

.lms-layout header .contain a {
    color: #fff;
}

/* 메뉴 우측 */
.lms-layout header .top .p-menubar {
    padding-bottom: 1.5rem;
}

.lms-layout header .top .p-menubar {
    border: 0;
    padding: 0;
    color: #fff;
    background-color: transparent;
}

.lms-layout header .top .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #fff;
}

.lms-layout header .top .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #fff;
}

.lms-layout header .top .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #fff;
}

.lms-layout header .top .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link {
    padding: 0 0 0.75rem 0;
}


.lms-layout header .top .p-menubar .p-menuitem.p-highlight>.p-menuitem-content {
    background-color: transparent;
    color: #fff;
}

.lms-layout header .top .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
    background-color: transparent;
}

.lms-layout header .top .p-menubar .p-submenu-list {
    z-index: 3;
    white-space: nowrap;
    right: 0;
    width: auto;
}

.lms-layout header .top .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
    color: #fff;
    background: transparent;
}

.lms-layout .tab-menu {
    display: flex;
    height: 59px;
    max-width: 50%;
}

.lms-layout .bottom .tab-menu {
    display: flex;
    max-width: 100%;
    background-color: #ddd;
    position: relative;
    justify-content: center;
    padding-top: 8px;
}

.lms-layout .bottom .tab-menu>li>a {
    color: #000;
}

.lms-layout .tab-menu>li {
    color: #fff;
    width: 240px;
    text-align: center;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    overflow: hidden;
}

.lms-layout .tab-menu>li>a {
    display: inline-block;
    width: 100%;
    height: 100%;
    padding-top: 17px;
}

.lms-layout .tab-menu>li>a>i {
    margin-right: 5px;
    vertical-align: middle;
}

.lms-layout .tab-menu>li a.active {
    background-color: #F7F7F7;
    color: #222;
}

.lms-layout header .submenulist {
    border-bottom: 1px solid #ddd;
    width: 100%;
    position: absolute;
    left: 0;
    top: 100%;
    height: 70px;
}

.lms-layout header .submenulist.no-items {
    display: none;
}

.lms-layout header .submenulist.has-items {
    height: 70px;
}

.lms-layout header .submenulist {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    z-index: 2;
}

.lms-layout header .submenulist li .sub-menu-item {
    color: #999;
}

.lms-layout header .submenulist li .sub-menu-item.active {
    color: #E8374A;
    font-weight: 600;
}

.p-tieredmenu .p-menuitem {
    font-family: "NanumSquare", sans-serif;
}

/* 모바일 헤더 */
.lms-layout header .submenulist{
    position: static;
}
:focus-visible {
    outline: none;
}

/* 서브 페이지 시작 */
.lms-layout .contentBox {
    padding-top: 30px;
   /*  margin-top: 70px; */
    padding-bottom: 3rem;
    /* height: 100vh; */
}

.lms-layout .contentBox.no-submenu {
    margin-top: 0;
}

.lms-layout .p-breadcrumb {
    border: 0;
    flex-wrap: wrap;
    gap: 8px;
}

.lms-layout .flexcont .contain {
    display: flex;
    gap: 15px;
}

.leftSideBox {

    width: 100%;
    max-width: 395px;
}

.leftSideBox>div {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    height: fit-content;
}

.leftSideBox h3 {
    font-weight: 800;
    font-size: 1.125rem;
    margin-top: 0;
}


.noticeList {
    max-height: 295px;
    /* 스크롤을 표시할 최대 높이 */
    overflow: hidden;

}

.scrollable {
    list-style-type: none;
    /* 목록의 기본 스타일 제거 */
    padding: 0;
    /* 목록의 내부 간격 제거 */
    margin: 0;
    /* 목록의 외부 여백 제거 */
    overflow-y: auto;
    /* 수직 스크롤을 표시하는 속성 */
}

.leftSideBox li {
    background-color: #F9F9F9;
    border-radius: 10px;
    margin-bottom: 10px;
}

.leftSideBox li .notilistItem {
    padding: 20px 10px;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #111;
    font-weight: 600;
}

.leftSideBox li .notilistItem .num {
    background-color: #555;
    border-radius: 5px;
    text-align: center;
    padding: 5px 8px;
    color: #fff;
    margin-right: 10px;
    font-size: 12px;
    font-weight: 600;
}

.leftSideBox li .notilistItem .txt {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 185px;
    display: inline-block;

}

.leftSideBox li .notilistItem i {
    margin-left: auto;
    flex: none;
}

.leftSideBox .moreNotibtn {
    background-color: transparent;
    border: 0;
    text-align: center;
    width: 100%;
    color: #777;
    font-size: .9375rem;
    font-weight: 600;
    margin-top: 20px;
    cursor: pointer;
    padding: 10px 0;

}

.contBox {
    background-color: #fff;
    border-radius: 10px;
    width: calc(100% - 395px);
    padding-left: 20px;
    padding-right: 20px;
}

.contBox h3 {
    font-weight: 800;
    font-size: 1.125rem;
    margin-bottom: 43px;
}

.filterBox {
    display: flex;
    gap: 8px;
    align-items: baseline;
    flex-wrap: wrap;
}

.filterBox label {
    color: #777;
    font-size: .875rem;
}

.filterBox .searchbtn {
    background: #E8374A;
    border: 0;
    border-radius: 8px;
    width: 120px;
}

.border {
    border: 1px solid #d1d5db;
}

.tableBox .p-datatable .p-datatable-thead>tr>th {
    background-color: transparent;
    border-bottom: 1px solid #000;
    font-family: "NanumSquare", sans-serif;
    white-space: nowrap;
}

.tableBox .p-datatable .p-datatable-tbody>tr>td {
    font-family: "NanumSquare", sans-serif;
    white-space: nowrap;
}

.tableBox .p-datatable .p-datatable-tbody>tr>td.deltebtn .p-button {
    color: #627FED;
}

.showtxt {
    color: #E8374A;
    font-size: .875rem;
    margin: 35px 0;
    font-weight: 500;
}

.listmob {
    display: none;
}
.screen-reader-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%);
    white-space: nowrap;
}
 
.showtxt>p {
    margin: 0;
}

.showtxt>p.total {
    font-size: 16px;
    font-weight: 600;
}

.tableBox .p-tag {
    border-radius: 22px;
    font-family: "NanumSquare", sans-serif;
}

.tableBox .p-tag.p-tag-success {
    background-color: #FFE3E6;
    color: #E8374A;
}

.tableBox .p-tag.p-tag-secondary {
    background-color: #F1F1F1;
    color: #8b8b8b;
}

.enterBtn {
    background-color: transparent;
    border: 1px solid #E8374A;
    color: #E8374A;
    padding: 7px 20px;
    border-radius: 20px;
    font-weight: 600;
}

.p-datatable .p-column-header-content {
    justify-content: center;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    min-width: 2rem;
    height: 2rem;
    margin: 0;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: transparent;
    color: #E8374A;
    border: 1px solid #E8374A;
}

.p-dialog {
    font-family: "NanumSquare", sans-serif;
}

.p-dialog .p-dialog-header {
    /*     border-top-right-radius: 20px;
    border-top-left-radius: 20px; */
    border-bottom: 1px solid #ddd;
    padding: 17px;
}

.p-dialog .p-dialog-header .p-dialog-title {
    font-size: 16px;
    color: #212121;
    font-weight: 600;
}

.p-dialog .p-dialog-content h4 {
    font-size: 1.25rem;
    color: #111;
    font-weight: 800;
}

.p-dialog .p-dialog-content p {
    line-height: 26px;
}


/* 브러드크럼 */
.p-breadcrumb {
    background-color: transparent;
    font-family: "NanumSquare", sans-serif;
    margin-bottom: 30px;
}

.nowpage {
    font-weight: bold;
}


/* 수업등록 */
.fullBox {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 2.5rem 4.0625rem;
}

.p-calendar .p-inputtext {
    border: 1px solid #d1d5db;
}

.p-calendar-w-btn-right .p-datepicker-trigger {
    background-color: transparent;
    color: #333;
    border-left: 0;
    border-color: #d1d5db;
    height: 43px;
}

.p-inputtext {
    height: 43px;
}

.form-group {
    margin-bottom: 30px;
}

.form-group.flex {
    gap: 1rem;
    align-items: center;
}

.form-group label {
    max-width: 200px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
}

.weekday-buttons {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
}

.weekday-buttons .p-button {
    background-color: #F1F1F1;
    color: #111;
    border: 0;
}

.weekday-buttons .p-button.selected {
    background-color: #222222;
    color: #fff;
}

.timeBox {
    display: flex;
    align-items: center;
}


.bottombtns {
    margin-top: 2rem;
    display: flex;
}

.p-button-timeregibtn {
    background-color: #222222;
    color: #fff;
    border: 0;
}

.p-button-timecancbtn {
    background-color: #E7E7E7;
    color: #999999;
    border: 0;
}

.p-button-cancbtn {
    background-color: #E7E7E7;
    color: #999999;
    border: 0;
    height: 50px;
}

.p-button-logout {
    background-color: transparent;
    color: #fff;
    border: 0;
    height: 50px;
}

.p-button-previewbtn {
    background-color: #E8374A;
    color: #fff;
    border: 0;
    height: 50px;
    text-align: center;
}

.addtimebtn {
    background-color: transparent;
    color: #555;
    border: 0;
    padding: 0;
    font-weight: 600;
}

.addtimebtn>span {
    border-radius: 50%;
    color: #999;
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 22px;
    border: 1px solid #999;
    text-align: center;
    margin-right: 8px;
}

.p-menu-list {
    font-family: "NanumSquare", sans-serif;
}

.selectedBox p {
    margin-top: 0;
    color: #E8374A;
    font-weight: 600;
}

.selectedBox .xicon {
    background-color: transparent;
    border: 0;
    color: #555;
    font-weight: 600;
    font-size: 13px;
    cursor: pointer;
}

.bottombtns .p-button {
    width: 200px;
}

.p-button-downloadBtn {
    background-color: #627FED;
    border: 0;
}

.p-button-downloadBtn2 {
    background-color: #0ea5e9;
    border: 0;
}

.bookBtn {
    border: 0;
    background-color: transparent;
    font-size: 16px;
    font-family: "NanumSquare", sans-serif;
    color: #627FED;
    cursor: pointer;
    font-weight: 600;
    padding: 0;
}

.mylogin {
    color: #fff;
    display: flex;
    align-items: center;
    gap: 16px;
}

.mylogin .myname {
    font-size: 17px;
}

.mylogin .myname i {
    margin-right: 10px;
}

.p-button.logout {
    padding: 0;
    background-color: transparent;
    border: 0;
}



/* 교재 팝업 */
.bookDia.p-dialog .p-dialog-content {
    padding: 1.5rem 2rem 1.5rem 2rem;
}


/* 학습자용 */
/* .lms-layout .flexcont.learner .contain>div.left {
    width: 50%;
} */
.lms-layout .flexcont.learner .contain>div.left {
    width: 50%;
}

.lms-layout .flexcont.learner .contain>div.right {
    width: 50%;
}

.toparea {
    display: flex;
    width: 100%;
    gap: 2%;
    flex-wrap: wrap;
}

.toparea>div .leftSideBox {
    max-width: none;
    width: 100%;
    height: 100%;
}

.classReservation {
    background-color: #fff;
    border-radius: 10px;
}

.classReservation h3 {
    font-weight: 800;
    font-size: 1.125rem;
    margin-top: 0;
    margin-bottom: 3rem;
}

.classReservation h3 small {
    color: #777;
    font-size: 12px;
    padding-left: 8px;
}

.custom-red {
    background-color: #E8374A;
}

.chartBox {
    padding-top: 1rem;
    justify-content: space-around;
}

.chartBox .righttxt {
    padding-left: 1.5rem;
    padding-top: 1.5rem;
}

.chartBox .righttxt h3 {
    margin: 0;
}

.chartBox .righttxt big {
    font-size: 3rem;
    color: #E8374A;
    font-weight: 900;
    padding: 10px 0;
    display: block;
}

.chartBox .righttxt small {
    font-size: 16px;
    color: #111;
    font-weight: 600;
}

.average {
    width: 100%;
    margin-top: 3.5rem;
    margin-bottom: 1rem;
    text-align: center;
}

.average>div {
    background-color: #555555;
    color: #fff;
    border-radius: 8px;
    width: auto;
    display: inline;
    padding: 8px 15px;
    font-size: 15px;
}

.average>div>span {
    font-weight: 600;
    font-size: 19px;
}

.fc .fc-button-primary {
    background-color: #e8374a;
    border-color: #e8374a;
}

.fc .fc-button-primary:hover {
    background-color: #c81728;
    border-color: #c81728;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
    background-color: #c81728;
    border-color: #c81728;
}

.fc .fc-button-primary:focus {
    box-shadow: none;
}

.fc-h-event .fc-event-title-container {
    cursor: pointer;
}

.fc .fc-popover {
    z-index: 1;
}

.pdfmodal.p-dialog .p-dialog-content:last-of-type {
    padding: 0;
}


.Side {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 2;
}

.selecttabele {
    width: 100%;
}

.Side>div {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    height: -webkit-fill-available;
}

.Side>div h3 {
    font-weight: 800;
    font-size: 1.125rem;
    margin-top: 0;
}

.Side>div .infoBox {
    background-color: #eee;
    border-radius: 10px;
    padding: 20px;
    color: #111;
    font-weight: 600;
    line-height: 1.5;
}

.center {
    background-color: #fff;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    flex: 3;
    padding-bottom: 20px;
}

.Right {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    max-width: 300px;
    width: 100%;
}

.teacherImg {
    background-color: #eee;
    border-radius: 10px;
    overflow: hidden;
}

.teacherImg img {
    max-width: 100%;

}

.Right .editor img {
    width: 100%;
    height: auto;
}

.fc-event-disabled {
    background-color: #ccc !important;
}

.header-with-tooltip i {
    font-size: 18px;
    color: #e8374a;
}

.booklistWrap {
    padding-top: 7rem;
    display: flex;
    gap: 2%;
    flex-wrap: wrap;
}

.booklistWrap .booklist-item {
    width: 18.4%;
    margin-bottom: 1rem;
}

.booklistWrap .booklist-item .thumnail {
    background-color: #ddd;
    height: 149px;
    text-align: center;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
}

.booklistWrap .booklist-item .thumnail .tit {
    color: #777;
    font-size: .875rem;
    font-weight: 700;
    letter-spacing: -0.009em;
    padding: 0 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
}


.show-more-btn {
    text-align: center;
    display: block;
}

.redbtn {
    background: #E8374A;
    border-color: #E8374A;
}

.nobtn {
    background: transparent;
    border: 0;
    color: #111;
}

.nobtn:focus {
    box-shadow: none;
}

.tablechk {
    border: 2px solid #d1d5db;
    border-radius: 6px;
    width: 22px;
    height: 22px;
}



.profileBox {
    background-color: #fff;
    border-radius: 10px;
    padding: 2.5rem 2rem;
    flex: 1;
}



.profileBox .imgbox {
    position: relative;
    width: fit-content;
    margin: 0 auto 1rem;
}

.profileBox .imgbox .img {
    width: 150px;
    height: 150px;
    border: 1px solid #ddd;
    border-radius: 50%;
    overflow: hidden;
}

.profileBox .imgbox img {
    width: 100%;
    height: 100%;
}

.profileBox .imgbox .uploadbtn {
    position: absolute;
    bottom: 0;
    right: 0px;
    border: 0;
    background-color: transparent;
    cursor: pointer;
}

.profileBox .imgbox i {

    font-size: 16px;
    background-color: #627ff0;
    padding: 6px 15px;
    border-radius: 5px;
    color: #fff;
}

.profileBox .profile .info {
    text-align: center;
    margin-bottom: 4rem;
}

.profileBox .profile .info p {
    margin: 0;
    font-weight: 700;
    color: #374151;
    line-height: 1.8;
}

.profileBox .profile .info>b {
    margin-bottom: 1.5rem;
    width: 100%;
    display: block;
    font-size: 20px;
}

.ttendance_rate {
    color: #E8374A;
    line-height: 1.5;
    text-align: center;
}

.ttendance_rate>div>* {
    margin: 0;
}

.ttendance_rate>div b {
    font-weight: 900;
}

.textbox .p-inputtextarea {
    width: 100%;
    resize: none;
}

.reasonModal .textbox .p-inputtextarea {
    height: 100px;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
    margin-top: 0;
    font-family: "NanumSquare", sans-serif;
}

.p-confirm-dialog #finalRegiDialog_content.p-dialog-content {
    justify-content: center;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin-left: 0;
}

/* 
.p-toast {
    top: 100px !important;
} */


/* 모바일 */

.listmob .table {
    width: 100%;
     border-collapse: collapse;

}

.listmob .table tr {
    display: block;
    background-color: #fff;
    border-radius: 10px;
    padding: 15px 20px;
    margin-bottom: 1rem;

}


.listmob .table td {
        display: block;
        padding: 0; /* 패딩 제거 */
        border: none; /* 테두리 제거 */
        position: relative;
        vertical-align: bottom;
        margin-bottom: 1px;
    }

    .listmob .table td::before {
        content: attr(data-label); /* 데이터 레이블 추가 */
        position: absolute;
        left: 0;
        font-weight: bold;
        background: #eee;
        visibility: hidden;
    }

.listmob .table tr .top {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: baseline;
}

.listmob .table tr .btns {
    margin-top: 8px;
    justify-content: end;
}

.listmob .table tr .btns>* {
    font-size: 16px;
    padding: 8px 12px;
}

.attendance {
    color: green;
    font-weight: 600;
}

.order {
    background-color: #EEEEEE;
    border-radius: 20px;
    padding: 5px;
    font-size: 13px;
}


.p-paginator {
    background-color: transparent;
    padding: 0;
}



/* 강사선택 ui */
.breadcrumb {
    flex: 2;
}

.teacherListBox {
    flex: 4;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 1.5rem;
    padding: 0 1rem;
}

.teacherListBox button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
}

.teacherListBox button.on img {
    border: 2px solid #e8374a;
}

.teacherListBox button>img {
    border: 1px solid #ddd;
    border-radius: 50%;
    height: 80px;
    width: 80px;
}

.teacherListBox button.on .t_name {
    font-weight: bold;
}

.teacherListBox button .t_name {
    font-size: 15px;
}

/* 미디어쿼리 */
@media(max-width:1280px) {

    .booklistWrap .booklist-item {
        width: 23.4%;
    }

}

@media(max-width:1018px) {
    .lms-layout .tab-menu>li {
        width: 169px;
    }
}


@media(max-width:991px) {
    .ham {
        display: block;
    }

    /*  .lms-layout header .contain {
        height: 70px;
    } */

    .lms-layout header {
        height: auto;
    }


    /*   .lms-layout header .top {
        padding-top: 0;
    } */

    .lms-layout header .top .p-splitbutton,
    .lms-layout header .contain .logo {
        padding-bottom: 0;
    }

    /* .lms-layout .contentBox {
        padding-top: 10px;
        margin-top: 30px;
    } */

    .App>div>.lms-layout {
        height: auto !important;
    }

    .lms-layout .flexcont .contain {
        flex-direction: column;
    }

    .lms-layout .flexcont .contain>div {
        width: 100%;
        max-width: none;
    }

    .lms-layout .flexcont.learner .contain>div.right {
        width: 100%;
        padding-bottom: 20px;
    }

    .lms-layout .flexcont.learner .contain>div.left {
        width: 100%;
    }

    .learnermain.contain .flex {
        flex-direction: column;
    }

    .Right {
        max-width: 100%;
        text-align: center;
    }

    .teacherImg {
        max-width: 260px;
        margin: 0 auto;
        width: 100%;
    }

    .teacherImg img {

        width: 100%;
    }

    .attendanceStatus .contBox {
        width: 100%;
    }

    .booklistWrap {
        padding-top: 3rem;
    }

    .booklistWrap .booklist-item {
        width: 48%;
    }
}

@media(max-width:960px) {

    .lms-layout header .top .p-menubar .p-menubar-root-list .p-submenu-list {
        position: absolute;
        margin-top: 0.5rem;
    }

    .lms-layout header .top .p-menubar .p-menubar-root-list {
        position: static;
        display: block;
        background-color: transparent;
    }

    .lms-layout header .top .p-menubar .p-menubar-button {
        display: none;
    }

    .lms-layout header .top .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link {
        padding: 0;
    }

    .lms-layout header .top .p-menubar .p-submenu-list {

        border: 1px solid #000;
    }
}


@media(max-width: 870px) {


    .lms-layout header .top {
        padding: 1rem 0;
    }

    .lms-layout .top .tab-menu {
        display: none;
    }

    .lms-layout header .bottom {
        display: block;
    }

    .timeBox {
        flex-direction: column;
        align-items: baseline;
        gap: 10px;
    }

    .timeBox .btns {
        display: flex;
        gap: 10px;
    }

    .p-button-timeregibtn,
    .p-button-timecancbtn {
        margin: 0;
    }

    .bottombtns .p-button {
        width: 135px;
    }


}

@media(max-width: 600px) {

    .leftSideBox {
        padding: 20px;
    }

    /* 모바일 기준 */
    /*  .lms-layout .contentBox {
        margin-top: 0;
    } */

    .lms-layout .p-breadcrumb {
        margin-bottom: 1rem;
    }

    .fullBox {
        padding: 1rem;
        background-color: transparent;
    }

    .bottombtns .p-button {
        margin-bottom: 1rem;
    }

    .listpc {
        display: none;
    }

    .listmob {
        display: block;
    }

    .contBox {
        background-color: transparent;
    }

    .form-group.flex {
        flex-direction: column;
        align-items: baseline;
    }

    .flex.form-group .addtime {
        width: 100%;
    }

    .addtimebtn {
        width: 100%;
        border: 1px solid #D9D9D9;
        border-radius: 10px;
        height: 45px;
        justify-content: center;
    }

    .bottombtns {
        flex-direction: column;
        width: 100%;
    }

    .bottombtns .p-button {
        width: 100%;
    }

    .classReservation {
        background-color: transparent;
    }

    .classReservation h3 {
        margin-bottom: 15px;
    }

    .classReservation h3 small {
        display: block;
        padding-left: 0;
        padding-top: 10px;
    }

    .contBox.calendar {
        padding: 0;
    }

    .contBox.calendar h3 {
        padding: 0 20px;
        margin-bottom: 20px;
    }

    .contBox.calendar .fc-media-screen {
        background-color: #fff;
        padding: 10px;
    }


}

@media(max-width: 548px) {

    .leftSideBox li .notilistItem .txt {
        max-width: 300px;
    }
}

@media(max-width: 500px) {
    .booklist-item {
        width: 100%;
    }

    .filterBox .searchbtn {
        margin-left: 4px !important;
        margin-top: 5px;
    }
}

@media(max-width: 430px) {
    .lms-layout .contentBox {
        padding-top: 0;
    }

    .mylogin .myname,
    .p-button.logout {
        font-size: 15px;
    }

    .lms-layout header .submenulist {
        gap: 20px;
    }

    .lms-layout .bottom .tab-menu>li>a {
        font-size: 15px;
    }

    .lms-layout header .submenulist li a {
        font-size: 15px;
    }

    .chartBox {
        flex-direction: column;
        align-items: center;
    }

    .classReservation h3 {
        flex-direction: column;
        gap: 1rem;
    }

    .leftSideBox li .notilistItem .txt {
        max-width: 200px;
    }

    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last {
        min-width: 2rem;
        height: 2rem;
        margin: 0;
    }

    .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-text {
        font-size: 13px;
        line-height: 1.2;
    }

    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last {
        min-width: 2rem;
        height: 2rem;
    }

    .p-paginator .p-paginator-first .p-3,
    .p-paginator .p-paginator-prev .p-3,
    .p-paginator .p-paginator-next .p-3,
    .p-paginator .p-paginator-last .p-3 {
        padding: 0.5rem !important;
    }

    .lms-layout .top .contain {
        width: 90%;
    }

    .lms-layout header .contain .logo {
        padding-bottom: 0;
        width: 80%;
    }

    .lms-layout .contain {
        padding: 0;
    }

    .form-group.flex {
        flex-direction: column;
        align-items: baseline;
    }

    .Side>div {
        background-color: transparent;
    }

    .Side>div .infoBox {
        background-color: #fff;
        font-size: 14px;
    }

    .Side>div h3 {
        font-size: 18px;
    }

    .center,
    .classReservation {
        border-radius: 0;
    }

    .booklistWrap .booklist-item {
        width: 100%;
    }

    .profileBox{
        margin: 20px;
    }

}

@media(max-width: 320px) {

    .toparea{
        display: block;
    }

    .lms-layout .bottom .tab-menu>li>a {
        font-size: 14px;
    }

    .lms-layout header .submenulist li a {
        font-size: 13px;
    }

    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last {
        min-width: 1rem;
        height: 2rem;
    }

    .p-paginator .p-paginator-first .p-3,
    .p-paginator .p-paginator-prev .p-3,
    .p-paginator .p-paginator-next .p-3,
    .p-paginator .p-paginator-last .p-3 {
        padding: 0 !important;
    }

}