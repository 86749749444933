/* 헤더 */
.site-layout header {
  width: 100%;
  height: 90px;
}

.utillWrap.mob {
  display: none;
}

.site-layout header .utillWrap {
  height: 100%;
  border-bottom: 1px solid #D9D9D9;
  position: relative;
}

.site-layout header .utillWrap .container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

.site-layout header .utillWrap .logo {
  color: #fff;
}

.site-layout header .utillWrap .utill>li>a {
  position: relative;
  font-size: 16px;
  color: #777;
  font-weight: 600;
  padding: 0 15px;
}


.site-layout header .utillWrap .utill>li.logout_btn button {
  position: relative;
  font-size: 16px;
  color: #777;
  font-weight: 600;
  padding: 0 15px;
}

.site-layout header .utillWrap .utill>li.movelink>a {
  color: #111;
}

.utillWrap ul {
  display: flex;
  padding: 0;
  margin: 0;
  height: 100%;
}

.utillWrap .utill .movelink>a {
  color: #111;
  font-size: 18px;
  font-weight: bold;
  height: 100%;
}

.utillWrap .utill>li {
  height: 100%;
  padding-top: 32px;
}

.utillWrap .utill .movelink>a svg {
  margin-left: 10px;
}

.utillWrap .utill .movelink>a svg path {
  stroke: #111;
}

.site-layout header .utillWrap .leftarea {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.service_subBox {
  z-index: 1;
  position: absolute;
  background-color: #fff;
  top: 90px;
  width: 100%;
  left: 0;
  border-bottom: 1px solid #ddd;
}

.service_subBox .container {
  display: flex;
  justify-content: flex-end;
}

.service_subBox .subBox {
  padding: 2rem;
}

.service_subBox .subBox ul {
  flex-wrap: wrap;
  display: flex;

}

.service_subBox .subBox li a {
  padding: 0 20px 20px;
  display: block;
  color: #111;
  font-family: 'NanumSquareLight';
  font-weight: 600;
}

.service_subBox {
  display: none;
}

.service_subBox.active {
  display: block;
}

.utillWrap.mob .container {
  justify-content: space-between;
  width: 100%;
}

.utillWrap.mob .hambtn {
  margin-left: auto;
  color: #000;
  background-color: transparent;
  border: 0;
}

.utillWrap.mob .hambtn .p-button-icon {
  font-size: 20px;
}

.mobmenuBox.p-sidebar .p-sidebar-header .sidermenu.p-sidebar .p-sidebar-header .p-sidebar-close,
.mobmenuBox.p-sidebar .p-sidebar-header .p-sidebar-icon {
  color: #111;
}

.mobmenuBox.p-sidebar {
  height: 100%;
}

.mobmenuBox.p-sidebar .p-sidebar-header {
  padding: 2rem;
}



.mobmenuBox.p-sidebar .p-sidebar-content {
  padding: 0 30px;
}

.mobmenuBox.p-sidebar .searchBox {
  width: 100%;
}

.mobmenuBox.p-sidebar .searchBox input {
  width: 96%;
  font-size: 18px;
}

.mobmenuBox.p-sidebar .searchBox input::placeholder {
  font-size: 18px;
}

.mobmenuBox .menulist .p-accordion .p-accordion-content {
  border: 0;
  padding: 0;
}

.mobmenuBox .menulist {
  padding-top: 39px;
}

.mobmenuBox .menulist a {
  color: #777;
  font-weight: 600;
  display: block;
  padding: 1.3rem 0px;
  font-size: 18px;
}

.mobmenuBox .menulist button {
  color: #777;
  font-weight: 600;
  padding: 1.3rem 0px;
  font-size: 18px;
}

.mobmenuBox .menulist .movelink a {
  color: #111;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobmenuBox .menulist .movelink button {
  color: #777;
  font-weight: 600;
  display: block;
  padding: 1.3rem 0px;
  font-size: 18px;
}

.mobmenuBox .menulist .movelink .submenu a {
  background-color: transparent;
  position: relative;
  border: 0;
}

.mobmenuBox .menulist .movelink .submenu a>svg {
  position: absolute;
  right: 0;
  margin-right: 0;
}

.mobmenuBox .menulist .submenu ul {
  max-height: 200px;
  overflow-y: auto;
}

.mobmenuBox .menulist .submenu ul li a {
  padding: 0 5px 15px 0;
  font-size: 16px;
  color: #828282;
}

.searchBox {
  background-color: #F5F6F7;
  border-radius: 30px;
  padding: 10px 20px;
  width: 367px;
}

.searchBox input {
  background-color: transparent;
  width: 93%;
}

.searchBox input::placeholder {
  color: #999;
  font-size: 14px;
}

.banner {
  position: relative;
}
.banner .p-carousel{
  max-height: 500px;
}
.banner .p-carousel .p-carousel-content{
  overflow: hidden;
}
.banner .custom-pagination {
  position: relative;
}


.banner .custom-pagination .paginationBox {
  position: absolute;
  right: 10px;
  bottom: 35px;
  justify-content: flex-end;
  display: flex;
  gap: 6px;
}

.banner .custom-pagination .paginationBox .pagingbtn {
  background-color: #474747;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  border: 0;
}

.countBox {
  background-color: #cdcdcd;
  border-radius: 20px;
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.countBox .page-number {
  display: inline-block;
  padding-right: 10px;
}

.countBox .moveicon {
  height: 13px;
  width: auto;
  padding-top: 1px;
  background-color: transparent;
  border: 0;
  padding: 0;
}


.banner code {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #888;
  z-index: 1;
  font-size: 30px;
}

.banner .carousel-item {
  position: relative;
	padding-bottom: 56.25%;
}
.banner .carousel-item img{
   position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the container while maintaining aspect ratio */

}
.carousel-item {
  width: 100%;
  position: relative;
}



.carousel-item code {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: #888;
  z-index: 2;
}

.carousel-item img {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.content section {
  margin-bottom: 5rem;
}

.content h2.listtitle {
  font-size: 1.5625rem;
  font-weight: 700;
  letter-spacing: -0.009em;
  text-align: center;
  font-weight: bold;
  padding-bottom: 58px;
  padding-top: 120px;
}

.listBox {
  display: flex;
  align-items: center;
  width: 100%;
}

.listBox .p-carousel{
  max-width: 100%;
  width: 100%;
}

.listBox .p-carousel .p-carousel-item {
    flex: 0 0 25%; /* 네 개의 아이템이 한 줄에 보이도록 설정 */
    max-width: 25%; /* 네 개의 아이템이 한 줄에 보이도록 설정 */
}

.listBox a {
  width: 100%;
  display: inline-block;
}

.listBox .p-carousel-item{
  padding: 0 24px;
}

.listBox .p-button {
  background-color: transparent;
  color: #000;
  border: 0;
  height: auto;
  overflow: visible;
  padding: 0.5rem;
}


.listBox .p-button:focus {
  box-shadow: none;
}


.thumnail {
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #ddd;
  	position: relative;
	padding-bottom: 56.25%;
}

.thumnail img {
	 position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.listBox .p-carousel .p-carousel-indicators {
  display: none;
}

.listconts .tit {
  margin-bottom: 8px !important;
  height: 20px;
  color: #111;
  font-size: 1.125rem;
  font-weight: 700;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.listconts .txt {
  margin: 0;
  color: #777;
  font-size: .875rem;
  font-weight: 700;
  letter-spacing: -0.009em;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 38px;
}


/* 고객사 */
.customerBox {
  height: 450px;
  margin-bottom: 0 !important;
}

.customerBox .carousel-item {
/*   max-width: 220px; */
  height: 74px;
  line-height: 60px;
  text-align: center;
}

.customerBox .carousel-item img {
  max-width: 100%;
  width: auto;
}

.customerListBox.container {
  padding-bottom: 120px;
}

.customerListBox ul {
  display: flex;
  flex-wrap: wrap;
  gap: 3%;
  justify-content: center;
}

.logo-scroller {
      width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
}

.logo-track {
   display: inline-block;
  animation: scroll 200s linear infinite;
}

.logo-scroller .logo-track .logo {
    width: 100px;
  display: inline-block;
  height: 100px;
  margin: 0 10px;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

/* 검색결과페이지 및 상세 */
.content .searchpg h2.listtitle {
  text-align: left;
  padding-top: 1rem;
  margin-bottom: 0;
}

.searchDetail .topBox {
  border-bottom: 2px solid #111111;
  padding-bottom: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.searchDetail .topBox a {
  border: 1px solid #111111;
  color: #111111;
  border-radius: 6px;
  padding: 5px 20px;
  font-size: 1rem;
  font-weight: 600;
}

.contactbtn {
  color: #fff;
  background-color: #111111;
  padding: 5px 20px;
  font-family: "NanumSquare", sans-serif;
  letter-spacing: 1.5px;
}

.ser_topbox {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.ser_topbox .txtBox {
      width: calc(100% - 200px);
}

.ser_topbox .txtBox h2 {
  font-size: 2rem;
  padding-bottom: 1rem;
  margin-bottom: 0;
}

.ser_topbox .txtBox p {
  font-size: 1.2rem;
}

.ser_bottombox {
  margin-top: 15px;
  border-top: 1px solid #111;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 3rem;
  font-weight: 600;
}

.ser_topbox .thumbnail {
  width: 200px;
}

.ser_topbox .thumbnail img {
  width: 100%;
}

.searchpgBox .listBox {
  flex-wrap: wrap;
  gap: 2%;
}

.searchpgBox .listBox a {
  max-width: 300px;
  width: 100%;
  margin-bottom: 2%;
}

/* 이용약관 */
.termsBox h1{
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  padding-bottom: 1rem;
}
.termsBox h2{
  font-size: 1rem;
  font-weight: bold;
}

.termsBox p{
  font-weight: 400;
  padding: 0;
  margin-top: 0;
  margin-bottom: 3rem;
  line-height: 1.5;
}
.termsBox ul {
  margin-bottom: 3rem;
}
.termsBox ul li{
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
  line-height: 1.5;
}

















/* 푸터 */

footer {
  height: 261px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: #222;
  color: #999999;
  padding: 50px 0 !important;

}

footer .f_logo {
  margin-bottom: 30px;
}

footer ul {
  padding: 0;
}

footer ul li {
  padding-bottom: 8px;
  font-size: 13px;
  font-weight: 600;
}

footer .container>div {
  display: flex;
  justify-content: space-between;
  gap: 3%;
}

.f_right .rightBox {
  display: flex;
}

footer .container .f_right p {
  font-size: 14px;
  margin-bottom: 1rem;
  font-family: "NanumSquare", sans-serif;
}

footer .container .f_right p.ftitle {
  font-weight: bold;
  font-size: 16px;
}

footer .container .f_right {
  display: flex;
  gap: 3rem;
}

footer .container .f_right .txt {
  color: #fff;
}

footer .container .f_right .txt a {
  color: #fff;
  font-family: "NanumSquare", sans-serif;
}


footer .container .mob {
  display: none;
}

footer .container .mob .f_logo {
  border-bottom: 1px solid #ffffff24;
  padding-bottom: 24px;
  margin-bottom: 0;
}

footer .container .mob .p-accordion-tab {
  border-bottom: 1px solid #ffffff24;
  font-weight: 600;
}

footer .container .mob .p-accordion-header .p-accordion-toggle-icon {
  position: absolute;
  right: 0;
}

footer .container .mob .p-accordion-header {
  position: relative;
}

footer .container .mob .p-accordion .p-accordion-header .p-accordion-header-link {
  background-color: transparent;
  border-radius: 0;
  color: #999;
  padding: 12px 0;
  font-size: 13px;
  border: 0;
}

footer .container .mob .p-accordion ul {
  display: flex;
  flex-direction: column;
}

footer .container .mob .p-accordion ul a {
  color: #fff;
  font-family: 'NanumSquareAcb';
}

footer .container .mob .p-accordion .p-accordion-content {
  background-color: transparent;
  color: #fff;
  border: 0;
  padding: 0;
}

footer .container .mob .addr {
  padding-top: 30px;
  font-size: 13px;
}

.contact-info {
    border-radius: 5px;

}
.contact-modal.p-dialog .p-dialog-content{
  padding: 0;
  padding: 2rem 1rem;
}
.contact-info p {
    font-size: 17px;
    color: #343a40;
    font-weight: bold;
    margin: 0;
}
.contact-info p:first-child{
  margin-bottom: 1rem;
}
.contact-info a {
    color: #333;
    transition: color 0.3s ease;
}

.contact-info a:hover {
    color: #0056b3;
}



/* 미디어쿼리 */
@media(max-width: 1230px) {
  .utillWrap .utill .movelink a {
    font-size: 15px;
  }

  .searchBox {
    width: 250px;
  }

  .searchBox input {
    width: 88%;
  }
}


@media (max-width: 1024px) {
    .listBox .p-carousel .p-carousel-item {
        flex: 0 0 33.33%; /* 세 개의 아이템이 한 줄에 보이도록 설정 */
        max-width: 33.33%; /* 세 개의 아이템이 한 줄에 보이도록 설정 */
    }
}

@media(max-width: 991px) {

  .content {
    height: 100%;
  }

  .searchpgBox .listBox a {
    max-width: none;
    width: 48%;
  }

  .banner .carousel-item {
    max-height: 300px;
  }

}




@media(max-width: 870px) {
  header {
    height: 70px;
  }

  .utillWrap.pc {
    display: none;
  }

  .utillWrap.mob {
    display: block;
  }


  .ser_topbox .txtBox h2 {
    font-size: 20px;
    font-weight: 600;
  }

  .ser_topbox .txtBox p {
    font-size: 16px;
  }

  footer {
    height: auto;
    padding: 40px 0 !important;
    position: static;
  }

  footer .container .pc {
    display: none;
  }

  footer .container .mob {
    display: block;
  }
}


@media (max-width: 768px) {
    .listBox .p-carousel .p-carousel-item{
        flex: 0 0 50%; /* 두 개의 아이템이 한 줄에 보이도록 설정 */
        max-width: 50%; /* 두 개의 아이템이 한 줄에 보이도록 설정 */
    }
}

@media(max-width: 767px) {
  .content h2.listtitle {
    padding-top: 67px;
    font-size: 20px;
  }

  .listconts .tit{
    margin-top: 10px;
  }

  .banner .custom-pagination {
    bottom: 6px;
  }

  .mobmenuBox.p-sidebar .searchBox input {
    width: 91%;

  }

  .mobmenuBox.p-sidebar .searchBox .searchIcon {
    display: inline-block;
    width: calc(100% - 91%);
    text-align: right;
  }

  .searchpgBox .listBox a {
    width: 100%;
    margin-bottom: 2rem;
  }

}


@media (max-width: 560px) {
       .listBox .p-carousel .p-carousel-item{
        flex: 0 0 100%; /* 한 개의 아이템이 한 줄에 보이도록 설정 */
        max-width: 100%; /* 한 개의 아이템이 한 줄에 보이도록 설정 */
    }

    .termsBox h1{
      font-size: 22px;
    }
}

@media(max-width: 530px) {
  .customerListBox.container{
    padding-bottom: 50px;
  }
  .listBox .carousel-item {
    padding: 0;
  }

  .listBox .p-carousel-item{
  padding:0;
}
}

/* @media(max-width: 450px){
 .banner {
    height: 570px;
    border: 1px solid red;
  }

  .banner .custom-pagination .paginationBox {
    justify-content: center;
  }

  .banner .custom-pagination {
    bottom: 20px;
  }
} */

@media(max-width: 430px) {


  .ser_topbox {
    flex-direction: column;
    align-items: baseline;
  }

  .ser_topbox .thumbnail {
    width: 100%;
  }

  .ser_topbox .txtBox{
    width: 100%;
  }

}