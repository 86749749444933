.SurveyBox {
    padding: 1.875rem;
    border: 0 solid #0000;
    background-color: #F7F7F7;
}

.SurveyBox .card {
    max-width: 800px;
    width: 90%;
    margin: 0 auto;
    background-color: #fff;
    padding: 50px 58px 0;
    border-radius: 20px;
    /* height: 900px; */
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.titBox {
    margin-bottom: 1rem;
}

.SurveyBox .card h1 {
    margin-top: 0;
}
.SurveyBox .scrollBox{
max-height: 600px;
height: 100%;
overflow-y: auto;
}
.SurveyBox .scrollBox form{
    padding: 1rem;

}
.SurveyBox fieldset {
    margin-left: 0;
    margin-right: 0;
    border: 0;
    padding: 0;
}

.SurveyBox fieldset .field {
    margin-bottom: 2rem;
}

.SurveyBox fieldset .field:last-child {
    margin-bottom: 0;
}

.SurveyBox .btns {
    margin-top: 2rem;
}

.SurveyBox .field .tit {
    margin-bottom: 15px;
}


.SurveyBox input {
    border: 1px solid #ddd;
    width: 100%;
    border-radius: 6px;
    height: 48px;
}


.SurveyBox input[type='date'],
.SurveyBox input[type='time'] {
    width: 14rem;
    padding: 1rem;
    font-family: "NanumSquare", sans-serif;
    font-size: 100%;
}

.SurveyBox input[type='date'] {
    position: relative;
    width: 100%;
    padding: 10px;
    background: url(../img/calendar.svg) no-repeat right 10px center / 17px auto;
}


.SurveyBox input[type='date']::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    color: transparent;
    cursor: pointer;
}


.SurveyBox input[type='date']::before {
    content: attr(placeholder);
    width: 100%;
    height: 100%;
}


.SurveyBox input[type='date']:valid::before {
    display: none;
}


.essential {
    color: red;
    padding-left: 5px;
}


.linear-scale-radio {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
}

.radio-label {
    margin: 0 10px;
}

.endtit {
    background: #e1e3fe;
    border-color: #c3c7fd;
    color: #6a73fa;
    border-radius: 5px;
    padding: .75rem 2.5rem .75rem 1.2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.SurveyBox fieldset .sec_titBox{
    margin-bottom: 1rem;
    border-bottom: 1px solid #505050;
    padding-bottom: 10px;
}
.SurveyBox fieldset .sec_tit{
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 10px;
}






/* 설문조사 결과페이지 */
.resultBox.SurveyBox {
    padding: 0;
}

.resultBox.SurveyBox .result {
    height: calc(100% - 150px);
}

.resultBox.SurveyBox .result form,
.resultBox.SurveyBox .result form .p-fluid {
    height: 100%;
}

.resultBox.SurveyBox .result form .p-fluid {
    max-height: max-content;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
    scrollbar-width: thin;
    scrollbar-color: #ccc #f0f0f0;
}

.resultBox.SurveyBox .result form .p-fluid::-webkit-scrollbar {
    width: 8px;
}

.resultBox.SurveyBox .result form .p-fluid::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
}

.resultBox.SurveyBox .result form .p-fluid::-webkit-scrollbar-track {
    background-color: #f0f0f0;
}
.resultBox.SurveyBox fieldset .sec_titBox{
border-bottom: 0;
}
.resultBox.SurveyBox fieldset .sec_titBox .sec_tit{
    background-color: #ddd;
    border-radius: 10px 10px 0 0;
    display: inline-block;
    padding: 10px 20px;
}
.resultBox.SurveyBox fieldset .field {
    padding-bottom: 1rem;
    border: 1px solid #ddd;
}

.resultBox.SurveyBox .field .tit {
    font-size: 18px;
    font-weight: 600;
    padding: 15px 20px;
    margin-bottom: 0;
}
.resultBox.SurveyBox .field .body {

    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-bottom: 2rem;
}
.resultBox.SurveyBox .field .body .subtit_box{
   padding: 1rem 2rem;
   font-weight: 700;
}
.resultBox.SurveyBox .field .total {
    color: red;
    font-size: 15px;
    font-weight: 500;
     padding: 0 2rem 1rem;
    display: block;
}
.resultBox.SurveyBox .field .body .no_info{
  padding: 1rem 2rem;
}
.resultBox.SurveyBox .field .surveylist {
    max-height: 400px;
    overflow-y: auto;
    
}

.resultBox.SurveyBox .field .surveylist li {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .2px;
    line-height: 20px;
    color: rgb(32, 33, 36);
    background-color: rgb(248, 249, 250);
    border-radius: 4px;
    margin: 4px 0 0;
    padding: 10px;
    padding:  1rem 2rem;
}












@media(max-width: 600px) {
    .SurveyBox .card {
        padding: 30px;
    }
}

@media(max-width: 430px) {
    .SurveyBox .card {
        height: 100%;
        box-shadow: none;
        border-radius: 0;
        max-width: none;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
    .SurveyBox .scrollBox {
    max-height: 494px;
}


}


@media(max-width: 375px) {
    
    .SurveyBox .scrollBox {
    max-height: 450px;
}


}

@media(max-width: 320px){
    .SurveyBox .scrollBox {
    max-height: 350px;
}
}



/* 설문조사 결과 인쇄 css */
@media print {
    body {
        -webkit-print-color-adjust: exact; /* Chrome, Safari */
        color-adjust: exact; /* Firefox, Edge */
        height: 100%;
        overflow: visible;
    }
    .SurveyBox .card {
        max-height: none;
        width: 100%;
        padding: 1rem;
        border: 0;
        margin: 0;
        background-color: #fff;
        border-radius: 0;
        height: auto;
        overflow: visible;
        position: static;
        transform: none;
    }
    .resultBox.SurveyBox .result {
        height: auto !important;
        overflow: visible !important;
    }
    .resultBox.SurveyBox .result form,
    .resultBox.SurveyBox .result form .p-fluid {
        overflow: visible !important;
        height: auto !important;
        max-height: none !important;
    }

    .surveylist {
        list-style-type: none;
        padding-left: 0;
        max-height: none !important;
        overflow: visible !important;
    }
    .resultBox.SurveyBox .field .tit {
        text-align: left;
        margin-bottom: 1rem;
    }

}