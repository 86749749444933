.accountBox {
    padding: 1.875rem;
    border: 0 solid #0000;
    background-color: #F7F7F7;
}

.accountBox .card {
    max-width: 570px;
    width: 90%;
    margin: 0 auto;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 70px 65px;
    border-radius: 20px;
}

.accountBox h4 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 0;
}

.accountBox .p-input-icon-left {
    width: 100%;
}

.accountBox .p-inline-message{
    margin-top: 5px;
}

.accountBox input {
    border: 1px solid #ddd;
    width: 100%;
        padding-left: 2.5rem;
}
.accountBox.join .flex input{
width: 60%;
}
.accountBox.join .flex {
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}
.accountBox.join .flex button{
    white-space: nowrap;

}
.accountBox input::placeholder {
    color: #777;
    font-weight: 600;
}

.accountBox .p-input-icon-left i {
    color: #111;
    z-index: 1;
}
.accountBox .p-input-icon-left .p-password{
    width: 100%;
}
.accountBox .btns {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.accountBox .btns button {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    border-radius: 8px;
    height: 50px;
}

.accountBox .btns a {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    border-radius: 8px;
    height: 50px;
    line-height: 48px;
    display: block;
}

.accountBox .btns .logbtn {
    background-color: #E8374A;
    color: #fff;
    border: 0;
    cursor: pointer;
}

.accountBox .btns .joinbtn {
    border: 1px solid #E8374A;
    color: #E8374A;
}

.new-account a {
    font-size: 16px;
    color: #333;
    font-weight: 600;
}

.new-account span,
.new-account i {
    vertical-align: middle;
}

.new-account i {
    font-size: 18px;
}

.accountBox .p-checkbox .p-checkbox-box.p-highlight {
    border-color: #E8374A;
    background: #E8374A;
}

.accountBox .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #e8374938;
    border-color: #E8374A;
}

.memtype label {
    font-weight: 600;
    cursor: pointer;
}

.accountBox .joinBox label {
    font-size: 17px;
    color: #555;
    margin-bottom: 8px;
    display: block;
    font-weight: 900;
}

.accountBox .joinBox .btns {
    flex-direction: row;
}

.accountBox .joinBox .groupBox {
    display: flex;
    gap: 8px;
}

.accountBox .joinBox .groupBox .p-dropdown {
    width: 50%;
}

/* 비밀번호 변경 */
.accountBox.password .card {
    max-width: 650px;
    padding: 4.375rem 3rem;
}

.accountBox.password .btns {
    margin-top: 2rem;
}

.accountBox.resetpw .tit{
color: #555;
text-align: center;
}

.accountBox small{
    color: #E8374A;
    font-weight: 600;
}


/* 회원탈퇴 */
.withdrawal{
    text-align: center;
    padding: 0 10px;
}
.withdrawal h1{
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.withdrawal-notice{
    padding-bottom: 5rem;
    font-size: 17px;
}
.withdrawal-notice .line{
    text-decoration: underline;
}
.withdrawal .agree{
   margin-bottom: 20px;
   display: flex;
   align-items: center;
   justify-content: center;
}
.withdrawal .agree label{
    cursor: pointer;
}
.withdrawalBtn{
    border-radius: 8px;
    background-color: #e8374a;
    border: 0;
    color: #fff;
    padding: 10px 30px;
    font-size: 15px;
    letter-spacing: 1.5px;
    cursor: pointer;
}
.password-dialog .content{
    padding-bottom: 1rem;
}
.password-dialog .password-input{
    border: 1px solid #d1d5db;
    padding: 0.75rem 0.75rem;
    border-radius: 6px;
    width: 100%;
}
.password-dialog .btns{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.password-dialog .btns button{
    border-radius: 5px;
    border: 0;
    color: #fff;
    padding: 10px 30px;
    font-size: 15px;
    letter-spacing: 1.5px;
    cursor: pointer;
}
.password-dialog .btns button.cancelBtn{
    background-color: #2c3e50;  
}
.password-dialog .btns button.confirmBtn{
    background-color: #e8374a;
}
.completion-dialog .btns{
    display: flex;
    justify-content: center;
}
.completion-dialog .btns button{
    border-radius: 5px;
    border: 0;
    background-color: #e8374a;
    color: #fff;
    padding: 10px 30px;
    font-size: 15px;
    letter-spacing: 1.5px;
    cursor: pointer;
}












/* 미디어쿼리 */
@media(max-width: 430px) {
    .accountBox {
        background-color: transparent;
    }

    .accountBox .card {
        box-shadow: none;
        border-radius: 0;
        max-width: none;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .accountBox.password .card .flex {
        display: inline-block !important;
        width: 100%;
    }

    .accountBox.password .card .flex .idinput {
        width: 90%;
        display: inline-block;
        margin-right: 8px;
    }

    .accountBox.password .card .flex .p-inputtext {
        display: block;
        width: 100% !important;

    }

    .accountBox.password .card .flex .domainsel {
        width: 100%;
        margin-top: 8px;
    }

    .accountBox.password .card .flex .selfinput{
        margin-top: 8px;
    }

    .accountBox.password .btns {
        margin-top: 0;
    }

    .accountBox .btns .logbtn {
        font-size: 15px;
    }
    .accountBox.resetpw .tit > br{
        display: none;
    }
    .accountBox.join .flex{
        flex-direction: column;
        align-items: baseline;
    }
    .accountBox.join .flex input, .accountBox.join .flex Button{
        width: 100%;
    }

}

@media(max-width: 320px) {
    .accountBox.password .card .flex .idinput {
        width: 80%;
    }

    .dn{
        display: none;
    }
}