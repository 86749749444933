
@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');

body {
  margin: 0;
  font-family: "NanumSquare", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "NanumSquare", sans-serif;
}
